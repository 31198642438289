/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../_services/server.service';

class ModalImportInstitution extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',
            success: '',

            selectedFile: [],

        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
    }


    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});

        let file = document.getElementById('fileinput').files[0];

        // stop here if form is invalid
        if (!(file)) {
            toast.error("Nu ati selectat fisierul");
            return;
        }

        serverService.importInstitution(file, this.props.selectedInstitution.IdInstitution)
            .then(result => {
                if(result.status === 1){
                    let messageStr = "Date importate cu succes";
                    toast.success(messageStr);
                    this.props.onInstitutionImported(result.extra);

                    let successesStr = result.successes.map((item, key) =>
                        <li>{item}</li>
                    );

                    this.setState({ success:successesStr});

                } else {

                    let errorsStr = result.errors.map((item, key) =>
                        <li>{item}</li>
                    );

                    this.setState({ error:errorsStr});
                    toast.error("Import esuat");
                }
            });
    }


    render() {
        return (
            <Modal
                {...this.props}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Importa
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ color: 'red' }}>
                        {this.state.error}
                    </p>

                    <p style={{ color: 'green' }}>
                        {this.state.success}
                    </p>

                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group>
                            <Form.Label>*Alege fisier de import</Form.Label>
                            <Form.Control size="sm" type="file"
                                          id="fileinput"
                                          placeholder="Selectati fisierul"
                                          name="selectedFile"
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <br/>

                        <Button variant="flat" type="submit">
                            Importa
                        </Button>
                    </Form>
                </Modal.Body>
                <br/>
            </Modal>
        );
    }

}

export default ModalImportInstitution;