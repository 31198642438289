import React from 'react';
import { toast } from "react-toastify";

import {Button,ButtonToolbar} from 'react-bootstrap'

import { serverService } from '../../_services/server.service';

const $ = require('jquery');
$.DataTable = require('datatables.net');

require( 'datatables.net-bs4' );
require( 'datatables.net-select-bs4' );
require('../datatables.selected.css');

const columns = [
    {
        title: 'Nume elev',
        width: 150,
        data: 'StudentName'
    },
    {
        title: 'Nume test',
        width: 100,
        data: 'TestName'
    },
    {
        title: 'Nota',
        width: 100,
        data: 'Grade'
    },
];

class ReportsTable extends React.Component {
    constructor(...args) {
        super(...args);

        this.state = {

        };

    }
    componentDidMount() {
        var table = $(this.refs.main).DataTable({
            //dom: '<"data-table-wrapper"<"toolbar"><"bottom"f>rtip>',
            dom: "<'data-table-wrapper'<'row'<'col-md-6 my-auto'<'toolbar'>l><'col-md-6'f>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>" +
                    ">",
            data: this.props.reports,
            columns,
            ordering: false,
            paging:true,
            select: false,

            columnDefs: [
                {
                    "render": function ( data, type, row ) {

                        var toDisplay = "";
                        var Status = row['TestInstanceStudentStatus'];
                        if(Status === null){
                            toDisplay = "Absent";
                        } else if(Status == 4){
                            toDisplay = "0 - Descalificat";
                        } else {
                            toDisplay = Math.round(data * 9 + 1);
                        }

                        return toDisplay;
                    },
                    "targets": 2
                }
            ],

            language: {
                "sProcessing":   "Procesează...",
                "sLengthMenu":   "Afișează _MENU_ înregistrări pe pagină",
                "sZeroRecords":  "Nu am găsit nimic - ne pare rău",
                "sInfo":         "Afișate de la _START_ la _END_ din _TOTAL_ înregistrări",
                "sInfoEmpty":    "Afișate de la 0 la 0 din 0 înregistrări",
                "sInfoFiltered": "(filtrate dintr-un total de _MAX_ înregistrări)",
                "sInfoPostFix":  "",
                "sSearch":       "Caută:",
                "sUrl":          "",
                "oPaginate": {
                    "sFirst":    "Prima",
                    "sPrevious": "Precedenta",
                    "sNext":     "Următoarea",
                    "sLast":     "Ultima"
                },
                select: {
                    rows: {
                        _: "",
                        0: "",
                        1: ""
                    }
                }
            }
        });

    }
    componentWillUnmount(){
        $('.data-table-wrapper')
            .find('table')
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(oldProps) {
        if(this.props.reports !== oldProps.reports){
            reloadTableData(this.props.reports);
        }
    }

    render() {
        return (
            <div>
                <table ref="main" className="table table-striped table-bordered" />
            </div>);
    }

}


function reloadTableData(values) {
    const table = $('.data-table-wrapper')
        .find('table')
        .DataTable();
    table.clear();
    table.rows.add(values);
    table.draw();
}

export default ReportsTable;

