import React from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'bootstrap/dist/css/bootstrap.css';

import MyNavbar from '../ui/_other/MyNavbar';

import {  Route, withRouter,Switch,Redirect } from 'react-router-dom';

import { PrivateRoute } from '../_components/PrivateRoute';

import {LoginPage} from '../ui/LoginPage/LoginPage';
import {InstitutionsPage} from '../ui/InstitutionsPage/InstitutionsPage';
import {StudentsPage} from "../ui/StudentsPage/StudentsPage";
import {TeachersPage} from "../ui/TeachersPage/TeachersPage";

import {TestsPage} from "../ui/TestsPage/TestsPage";
import {QuestionsPage} from "../ui/QuestionsPage/QuestionsPage";
import {ReportsPage} from "../ui/ReportsPage/ReportsPage";

import {ResetPasswordPage} from "../ui/ResetPasswordPage/ResetPasswordPage";



class App extends React.Component {

    constructor(props) {
        super(props);

        // localStorage.removeItem("user")

        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({user:JSON.parse(localStorage.getItem("user"))});
            //alert("Route change!" + this.props.location.pathname);
        }
    }

    render() {
        return (
        <div className="container">
            <style type="text/css">
                {`
                    .btn-flat {
                      background-color: #F86754;
                      color: white;
                    }

                    .page-item.active  .page-link{
                        background-color: #F86754;
                        color: white;
                    }

                    .page-link {
                        color: #F86754;
                    }

                    table.dataTable tbody>tr.selected, table.dataTable tbody>tr>.selected {
                        background-color: #F86754;
                    }

                    .custom-select{
                        width:80px;
                    }

                    .pagination {
                        float:right;
                    }

                    .dataTables_filter {
                        float:right;
                    }
                `}
            </style>

            <ToastContainer autoClose={3000} hideProgressBar={true} />
            <MyNavbar user={this.state.user} />
            <Switch>
                <Route path="/login" component={LoginPage} />
                <Route path="/reset_password" component={ResetPasswordPage} />
                <PrivateRoute exact path="/" component={TestsPage} />
                <PrivateRoute exact path="/institutii" component={InstitutionsPage} />
                <PrivateRoute exact path="/profesori" component={TeachersPage} />
                <PrivateRoute exact path="/elevi" component={StudentsPage} />
                <PrivateRoute exact path="/teste" component={TestsPage} />
                <PrivateRoute exact path="/intrebari" component={QuestionsPage} />
                <PrivateRoute exact path="/rapoarte" component={ReportsPage} />
                <Redirect from='*' to='/' />
            </Switch>
        </div>
        );
    }
}

export default withRouter(props => <App {...props} />);