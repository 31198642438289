/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../_services/server.service';
import ClassesSelectUI from '../_other/ClassesSelectUI';

class ModalAddNewStudent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',

            Name: "",
            Email: "",
            IdClass: 0,

            selectedUser: {},

            classes:[],
            selectedClassIndex:-1,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onClassSelected = this.onClassSelected.bind(this);
    }

    componentDidMount(){
        console.log("didmount - IdInst: " + this.props.selectedInstitution.IdInstitution);
        serverService.getAllClassesByIdInstitution(this.props.selectedInstitution.IdInstitution).then(classesResponse => {
            this.setState({classes: classesResponse.data});
        });
    }


    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});
        const {Name,Email} = this.state;

        var SelectedIdClass = 0;
        if(this.state.selectedClassIndex >= 0){
            SelectedIdClass = this.state.classes[this.state.selectedClassIndex].IdClass;
        }

        // stop here if form is invalid
        if (!(Name)) {
            toast.error("Nu ati introdus toate datele obligatorii");
            return;
        } else if (SelectedIdClass == 0) {
            toast.error("Este necesara selectarea unei clase");
            return;
        }


        let user = {'UserType':3,'Name':Name,'Email': Email };
        let student = {'UserType':3,'IdClass': SelectedIdClass };

        if(this.state.selectedUser && typeof(this.state.selectedUser.IdUser) !== "undefined"){
            user.IdUser = this.state.selectedUser.IdUser;
            student.IdUser = this.state.selectedUser.IdUser;
        }

        serverService.insertOrUpdateStudent(user,student)
            .then(result => {
                if(result.status === 1){
                    let messageStr = "Elev adaugat";
                    if(this.state.selectedUser && typeof(this.state.selectedUser.IdUser) !== "undefined"){
                        messageStr = "Elev adaugat";
                    }
                    toast.success(messageStr);
                    this.props.onUserAdded(result.extra);
                } else {
                    toast.error("Adaugare esuata");
                }
            });
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.selectedInstitution !== prevState.selectedInstitution){
            this.setState({selectedInstitution:this.props.selectedInstitution});

            console.log("IdInst: " + this.props.selectedInstitution.IdInstitution);

            serverService.getAllClassesByIdInstitution(this.props.selectedInstitution.IdInstitution).then(classesResponse => {
                this.setState({classes: classesResponse.data});
            });
        }

        if(this.props.selectedUser !== prevState.selectedUser){
            this.setState({selectedUser:this.props.selectedUser});

            if(this.state.selectedUser && typeof(this.state.selectedUser.IdUser) !== "undefined"){
                this.setState({
                    Name: this.props.selectedUser.Name,
                    Email:  this.props.selectedUser.Email,
                });
            } else {
                this.setState({
                    Name: "",
                    Email: "",
                    selectedClassIndex:-1
                });
            }
        }
    }


    render() {

        let titleStr = "Adaugare elev";
        if(this.state.selectedUser && typeof(this.state.selectedUser.IdUser) !== "undefined"){
            titleStr = "Editare elev";
        }

        return (
            <Modal
                {...this.props}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {titleStr}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit}>

                        <Form.Group controlId="formBasicName">
                            <Form.Label>*Nume elev</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Introduceti nume"
                                          name="Name"
                                          value={this.state.Name}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicName">
                            <Form.Label>*Email elev</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Introduceti emailul"
                                          name="Email"
                                          value={this.state.Email}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>


                        <ClassesSelectUI name="IdClass"
                                           classes={this.state.classes}
                                           onClassSelected ={this.onClassSelected}
                                           selectedValueId={this.state.IdClass}
                        />

                        <br/><br/>

                        <Button variant="flat" type="submit">
                            {titleStr}
                        </Button>
                    </Form>
                </Modal.Body>
                <br/>
            </Modal>
        );
    }


    onClassSelected(selectedClassId){
        var classes = this.state.classes;
        var selectedClassIndex = -1;
        for(var i=0;i<classes.length;i++){
            if(classes[i].IdClass === selectedClassId){
                selectedClassIndex = i;
                break;
            }
        }
        this.setState({selectedClassIndex: selectedClassIndex});
    }

}

export default ModalAddNewStudent;