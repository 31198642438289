/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../_services/server.service';

class ModalAddNewQuestion extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',

            Question: "",
            Answer1: "",
            Answer2: "",
            Answer3: "",
            Answer4: "",
            CorrectAnswer: "",
            AnswerTime: "",

            selectedQuestion: {}
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});
        const {Question,Answer1,Answer2,Answer3,Answer4,CorrectAnswer,AnswerTime} = this.state;


        // stop here if form is invalid
        if (!(Question && Answer1 && Answer2 && Answer3 && Answer4  && CorrectAnswer && AnswerTime) ) {
            toast.error("Nu ati introdus toate datele obligatorii");
            return;
        }

        let user = JSON.parse(localStorage.getItem('user'));

        let question = {'IdTest': this.props.selectedTest.IdTest, 'Question':Question ,
            'Answer1': Answer1,'Answer2': Answer2,'Answer3': Answer3,'Answer4': Answer4
            ,'CorrectAnswer': CorrectAnswer,'AnswerTime': AnswerTime};
        if(this.state.selectedQuestion && typeof(this.state.selectedQuestion.IdQuestion) !== "undefined"){
            question.IdQuestion = this.state.selectedQuestion.IdQuestion;
        }

        serverService.insertOrUpdateQuestion(question)
            .then(result => {
                if(result.status === 1){
                    let messageStr = "Intrebare adaugata";
                    if(this.state.selectedQuestion && typeof(this.state.selectedQuestion.IdQuestion) !== "undefined"){
                        messageStr = "Intrebare editata";
                    }
                    toast.success(messageStr);
                    this.props.onQuestionAdded(result.extra);
                } else {
                    toast.error("Adaugare esuata");
                }
            });
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.selectedQuestion !== prevState.selectedQuestion){
            this.setState({selectedQuestion:this.props.selectedQuestion});

            if(this.state.selectedQuestion && typeof(this.state.selectedQuestion.IdQuestion) !== "undefined"){
                this.setState({
                    Question: this.props.selectedQuestion.Question,
                    Answer1:  this.props.selectedQuestion.Answer1,
                    Answer2:  this.props.selectedQuestion.Answer2,
                    Answer3:  this.props.selectedQuestion.Answer3,
                    Answer4:  this.props.selectedQuestion.Answer4,
                    CorrectAnswer: this.props.selectedQuestion.CorrectAnswer,
                    AnswerTime: this.props.selectedQuestion.AnswerTime,
                });
            } else {
                this.setState({
                    Question: "",
                    Answer1: "",
                    Answer2: "",
                    Answer3: "",
                    Answer4: "",
                    CorrectAnswer: "",
                    AnswerTime: "",
                });
            }
        }
    }


    render() {

        let titleStr = "Adaugare intrebare";
        if(this.state.selectedQuestion && typeof(this.state.selectedQuestion.IdQuestion) !== "undefined"){
            titleStr = "Editare intrebare";
        }

        return (
            <Modal
                {...this.props}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {titleStr}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit}>

                        <Form.Group controlId="formBasicQuestionName">
                            <Form.Label>*Intrebare</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Introduceti intrebarea"
                                          name="Question"
                                          value={this.state.Question}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicAnswer1">
                            <Form.Label>*Raspuns1</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Introduceti raspuns1"
                                          name="Answer1"
                                          value={this.state.Answer1}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicAnswer2">
                            <Form.Label>*Raspuns2</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Introduceti raspuns2"
                                          name="Answer2"
                                          value={this.state.Answer2}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicAnswer3">
                            <Form.Label>*Raspuns3</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Introduceti raspuns3"
                                          name="Answer3"
                                          value={this.state.Answer3}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicAnswer4">
                            <Form.Label>*Raspuns4</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Introduceti raspuns4"
                                          name="Answer4"
                                          value={this.state.Answer4}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>


                        <Form.Label>Raspuns Corect</Form.Label>
                        <Form.Control as="select" name="CorrectAnswer" multiple
                                      value={this.state.CorrectAnswer}
                                      onChange={this.handleChange}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                        </Form.Control>

                        <br/>

                        <Form.Group controlId="formBasicAnswerTime">
                            <Form.Label>*Secunde raspuns</Form.Label>
                            <Form.Control size="sm" type="number" placeholder="Introduceti secunde raspuns"
                                          name="AnswerTime"
                                          value={this.state.AnswerTime}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <br/>

                        <Button variant="flat" type="submit">
                            {titleStr}
                        </Button>
                    </Form>
                </Modal.Body>
                <br/>
            </Modal>
        );
    }

}

export default ModalAddNewQuestion;