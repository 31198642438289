import React from 'react';
import {Form} from 'react-bootstrap'

class TestsSelectUI extends React.Component {

    constructor() {
        super();

        this.state = {
            selectedValueId: '?',
        };
    }

    onTestSelected = (event) => {
        this.setState({ selectedValueId: event.target.value });
        this.props.onTestSelected(event.target.value);
    };

    render () {
        let tests = this.props.tests;

        let optionItems = "";
        if(tests && tests.length > 0){
             optionItems = tests.map((test) => {
                 let selected = "";
                 if(test.IdTest === this.props.selectedValueId){
                     selected = "selected";
                 }
                return <option key={test.IdTest} value={test.IdTest} selected={selected}>{test.Name}</option>
             });
        }

        return (
            <Form.Group controlId="formBasicIdTest">
                <Form.Label>Selecteaza testul</Form.Label>
                <Form.Control onChange={this.onTestSelected} size="sm" as="select">
                    <option key='0'>Select</option>
                    {optionItems}
                </Form.Control>
            </Form.Group>
        )

    }
}

export default TestsSelectUI;
