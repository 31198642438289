import React from 'react';

import { serverService } from '../../_services/server.service';

import InstitutionsTable from './InstitutionsTable';

class InstitutionsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            institutions: []
        };

        this.onInstitutionAdded = this.onInstitutionAdded.bind(this);
        this.onInstitutionDeleted = this.onInstitutionDeleted.bind(this);
        this.onInstitutionImported = this.onInstitutionImported.bind(this);
    }


    componentDidMount() {
        this.setState({
            institutions: []
        });

        this.doFetchAll();
    }


    render() {
        return (
            <div className="col-md-10 col-md-offset-2 mx-auto">
                <br/>
                <br/>
                <InstitutionsTable institutions={this.state.institutions}
                                   onInstitutionAdded={this.onInstitutionAdded}
                                   onInstitutionDeleted={this.onInstitutionDeleted}
                                   onInstitutionImported={this.onInstitutionImported}
                />
            </div>
        );
    }

    doFetchAll(){
        serverService.getAllInstitutions().then(institutionsRes => {
            this.setState({institutions: institutionsRes.data})
        });
    }

    onInstitutionAdded(institution){
        this.doFetchAll();
    }

    onInstitutionDeleted(){
        this.doFetchAll();
    }

    onInstitutionImported(){
        //.doFetchAll();
    }
}

export { InstitutionsPage };