/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../_services/server.service';

class ModalSendMessageToUsers extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',
            success: '',
        };

        this.sendMessageToUsers = this.sendMessageToUsers.bind(this);
    }

    componentDidMount(){
    }


    render() {
        return (
            <Modal
                {...this.props}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Trimite mesaj de intampinare
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ color: 'red' }}>
                        {this.state.error}
                    </p>

                    <p style={{ color: 'green' }}>
                        {this.state.success}
                    </p>

                    <h5>Sunteti sigur ca doriti sa trimiteti mail catre toti utilizatorii institutiei?</h5>
                    <br/>

                    <Button variant="flat"
                            type="submit"
                            onClick={this.sendMessageToUsers}
                            disabled={this.state.loading}
                    >
                        Da, trimit mesaj de intampinare
                    </Button>

                </Modal.Body>
                <br/>
            </Modal>
        );
    }


    sendMessageToUsers() {
        this.setState({loading:true});
        serverService.sendMessageToUsers(this.props.selectedInstitution.IdInstitution)
            .then(result => {
                this.setState({loading:false});
                if(result.status === 1){
                    toast("Trimitere cu succes");
                    this.props.onMessageSent();
                } else {
                    toast.error("Trimitere esuata");
                }
            });
    }

}

export default ModalSendMessageToUsers;