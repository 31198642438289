import React from 'react';
import {Form} from 'react-bootstrap'

class InstitutionsSelectUI extends React.Component {

    constructor() {
        super();

        this.state = {
            selectedValueId: '?',
        };
    }

    onInstitutionSelected = (event) => {
        this.setState({ selectedValueId: event.target.value });
        this.props.onInstitutionSelected(event.target.value);
    };

    render () {
        let institutions = this.props.institutions;

        let optionItems = "";
        if(institutions && institutions.length > 0){
             optionItems = institutions.map((institution) => {
                 let selected = "";
                 if(institution.IdInstitution === this.props.selectedValueId){
                     selected = "selected";
                 }
                return <option key={institution.IdInstitution} value={institution.IdInstitution} selected={selected}>{institution.Name}</option>
             });
        }

        return (
            <Form.Group controlId="formBasicIdInstitution">
                <Form.Label>Selecteaza institutia</Form.Label>
                <Form.Control onChange={this.onInstitutionSelected} size="sm" as="select">
                    <option key='0'>Select</option>
                    {optionItems}
                </Form.Control>
            </Form.Group>
        )
    }
}

export default InstitutionsSelectUI;
