import React from 'react';
import { withRouter} from 'react-router-dom'

import {Navbar,Nav} from 'react-bootstrap'

import { Link,NavLink } from 'react-router-dom';


class MyNavbar extends React.Component {

    constructor(props) {
        super(props);
        this.onLogout = this.onLogout.bind(this);
    }

    render() {
        var labelStyle = {
            color: '#CCCCCC',
        };

        const { user } = this.props;
        return (
            <div>
                <Navbar bg="dark" variant="dark" expand="lg">
                    <Navbar.Brand href="/">EVEX</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">

                        {(user && user.AuthToken && user.UserType == 1) &&
                            <Nav className="mr-auto">
                                &nbsp;&nbsp;&nbsp;
                                <NavLink to="/institutii" activeStyle={{
                                    color:'white'
                                }}>Institutii</NavLink>

                                &nbsp;&nbsp;&nbsp;
                                <NavLink to="/profesori" activeStyle={{
                                    color:'white'
                                }}>Profesori</NavLink>

                                &nbsp;&nbsp;&nbsp;
                                <NavLink to="/elevi" activeStyle={{
                                    color:'white'
                                }}>Elevi</NavLink>
                            </Nav>


                        }

                        {(user && user.AuthToken && user.UserType == 2) &&
                            <Nav className="mr-auto">
                                &nbsp;&nbsp;
                                <NavLink to="/teste" activeStyle={{
                                    color:'white'
                                }}>Teste</NavLink>
                                &nbsp;&nbsp;
                                <NavLink to="/intrebari" activeStyle={{
                                    color:'white'
                                }}>Intrebari</NavLink>
                                &nbsp;&nbsp;
                                <NavLink to="/rapoarte" activeStyle={{
                                    color:'white'
                                }}>Rapoarte</NavLink>
                            </Nav>
                        }


                        {(user && user.AuthToken) &&
                        <Nav className="mr-right">
                            <span style={labelStyle}>
                                Autentificat ca: {user.Name}
                            </span>
                            &nbsp;&nbsp;
                            <NavLink to="/login" activeStyle={{
                                color:'white'
                            }}>Logout</NavLink>
                        </Nav>
                        }

                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }

    onLogout(){
        localStorage.removeItem('user');
        this.props.history.push("/login");
    }
}


export default withRouter(MyNavbar);

