import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from "react-toastify";
import { Row,Col } from "react-bootstrap";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        localStorage.removeItem('user');

        this.state = {
            submitted: false,
            loading: false,
            error: '',
            username: "",
            password: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
    }


    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password } = this.state;

        // stop here if form is invalid
        if (!(username && password)) {
            return;
        }

        this.setState({ loading: true });
        serverService.login(username, password)
            .then(
                response => {
                    if(response.status === 0){
                        toast.warn(response.message);
                        this.setState({ loading: false })
                    } else {

                         if(response.data.length == 0){
                            this.setState({ error:"Eroare date server", loading: false })
                        } else if(response.data[0].UserType == 3){
                             toast.warn("Contul de elev nu poate fi utilizat pentru aplicatia web Evex Online. Descarca aplicatia EVEX pentru elevi din Magazin Play sau AppStore.");
                             this.setState({ loading: false })
                         } else {
                            toast.success(response.message);

                            var user = response.data[0];
                            localStorage.setItem('user',JSON.stringify(user));

                            let { from } = this.props.location.state || { from: { pathname: "/" } };
                            if(user.UserType == 1){
                                from =  "/institutii";
                            } else  if(user.UserType == 2){
                                from =  "/teste";
                            }
                            this.props.history.push(from);
                        }
                    }
                },
                error => {
                    toast.error("Server error");
                    this.setState({ error:error, loading: false })
                }
            );
    }

    render() {
        const { username, password, submitted, loading, error } = this.state;

        return (
            <div className="col-md-6 col-md-offset-3 mx-auto">
                <br/>
                <br/>
                <h2>Autentificare</h2>
                <form name="form" onSubmit={this.handleSubmit}>
                    <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                        <label htmlFor="username">Email utilizator</label>
                        <input type="text" className="form-control" name="username" value={username} onChange={this.handleChange} />
                        {submitted && !username &&
                        <div className="help-block">Email-ul este obligatoriu</div>
                        }
                    </div>
                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                        <label htmlFor="password">Parola</label>
                        <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                        {submitted && !password &&
                        <div className="help-block">Parola este obligatorie</div>
                        }
                    </div>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <button className="btn btn-primary" disabled={loading}>Login</button>
                                {loading &&
                                <img alt="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>
                        </Col>
                        <Col>
                            <div className="text-right">
                                <a href="#" onClick={this.forgotPassword}>Am uitat parola</a>
                            </div>
                        </Col>
                    </Row>

                    {error &&
                    <div className={'alert alert-danger'}>{error}</div>
                    }
                </form>
            </div>
        );
    }

    forgotPassword(){
        const { username } = this.state;

        if(!username || username.length == 0){
            toast.warn("Este necesara completarea email-ului");
        } else {

            let emailValid = username.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

            if(!emailValid){
                toast.warn("Adresa de email nu este valida");
            } else {
                serverService.forgotPassword(username)
                    .then(
                        response => {
                            if(response.status === 0){
                                toast.warn(response.message);
                                this.setState({ loading: false })
                            } else {
                                toast.success("Link de reseatare trimis pe email");
                            }
                        },
                        error => {
                            toast.error("Server error");
                            this.setState({ error:error, loading: false })
                        }
                    );
            }
        }

    }
}

export { LoginPage };