import React from 'react';

import { serverService } from '../../_services/server.service';

import TestsTable from './TestsTable';

class TestsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tests: []
        };

        this.onTestAdded = this.onTestAdded.bind(this);
        this.onTestDeleted = this.onTestDeleted.bind(this);
    }


    componentDidMount() {
        this.setState({
            tests: []
        });

        this.doFetchAll();
    }


    render() {
        return (
            <div className="col-md-10 col-md-offset-2 mx-auto">
                <br/>
                <br/>
                <TestsTable tests={this.state.tests} onTestAdded={this.onTestAdded} onTestDeleted={this.onTestDeleted} />
            </div>
        );
    }

    doFetchAll(){
        let user = JSON.parse(localStorage.getItem('user'));
        serverService.getAllTestsByIdTeacher(user.IdUser).then(testsRes => {
            this.setState({tests: testsRes.data})
        });
    }

    onTestAdded(test){
        this.doFetchAll();
    }

    onTestDeleted(){
        this.doFetchAll();
    }
}

export { TestsPage };