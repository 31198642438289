/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../_services/server.service';

class ModalAddNewTest extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',

            Name: "",
            LocationName: "",
            Status: 0,

            selectedTest: {}
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});
        const {Name} = this.state;

        // stop here if form is invalid
        if (!(Name)) {
            toast.error("Nu ati introdus toate datele obligatorii");
            return;
        }

        let user = JSON.parse(localStorage.getItem('user'));

        let test = {'Name':Name ,'IdTeacher': user.IdUser, 'IdInstitution': user.IdInstitution };
        if(this.state.selectedTest && typeof(this.state.selectedTest.IdTest) !== "undefined"){
            test.IdTest = this.state.selectedTest.IdTest;
        }

        serverService.insertOrUpdateTest(test)
            .then(result => {
                if(result.status === 1){
                    let messageStr = "Test adaugat";
                    if(this.state.selectedTest && typeof(this.state.selectedTest.IdTest) !== "undefined"){
                        messageStr = "Test editat";
                    }
                    toast.success(messageStr);
                    this.props.onTestAdded(result.extra);
                } else {
                    toast.error("Adaugare esuata");
                }
            });
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.selectedTest !== prevState.selectedTest){
            this.setState({selectedTest:this.props.selectedTest});

            if(this.state.selectedTest && typeof(this.state.selectedTest.IdTest) !== "undefined"){
                this.setState({
                    Name: this.props.selectedTest.Name,
                    //Status:  this.props.selectedTest.Status,
                    //LocationName: this.props.selectedTest.LocationName,
                });
            } else {
                this.setState({
                    Name: "",
                    Status: 0,
                    LocationName:"",
                });
            }
        }
    }


    render() {

        let titleStr = "Adaugare test";
        if(this.state.selectedTest && typeof(this.state.selectedTest.IdTest) !== "undefined"){
            titleStr = "Editare test";
        }

        return (
            <Modal
                {...this.props}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {titleStr}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit}>

                        <Form.Group controlId="formBasicName">
                            <Form.Label>*Denumire test</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Introduceti denumire"
                                          name="Name"
                                          value={this.state.Name}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>


                        {/*<Form.Label>Status</Form.Label>*/}
                        {/*<Form.Control as="select" name="Status"*/}
                                      {/*value={this.state.Status}*/}
                                      {/*onChange={this.handleChange}>*/}
                            {/*<option value="0">Inactiva</option>*/}
                            {/*<option value="1">Activa</option>*/}
                        {/*</Form.Control>*/}

                        <br/><br/>

                        <Button variant="flat" type="submit">
                            {titleStr}
                        </Button>
                    </Form>
                </Modal.Body>
                <br/>
            </Modal>
        );
    }

}

export default ModalAddNewTest;