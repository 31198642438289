import { authHeader } from '../_helpers/auth-header';
import {authHeaderFile} from "../_helpers/auth-header";

export const serverService = {
    login,
    logout,
    getAllInstitutions,
    insertOrUpdateInstitution,
    deleteInstitution,
    importInstitution,
    sendMessageToUsers,

    getAllTestsByIdTeacher,
    insertOrUpdateTest,
    deleteTest,

    getAllQuestionsByIdTest,
    insertOrUpdateQuestion,
    deleteQuestion,
    importQuestions,

    getAllClassesByIdTeacher,
    getAllClassesByIdInstitution,
    getAllReportsByIdClass,

    getAllTeachersByIdInstitution,
    insertOrUpdateTeacher,
    deleteTeacher,

    getAllStudentsByIdInstitution,
    insertOrUpdateStudent,
    deleteStudent,

    updatePassword,
    forgotPassword,

};

const REACT_APP_SERVER_API_URL = "https://evexonline.ro/evex/_api/public/index.php";

function login(email, password) {
    console.log("Sending email: " + email + ", and pass: " + password);
    const requestOptions = {
        method: 'GET',
       // headers: { 'Content-Type': 'application/json' },
        //params: JSON.stringify({ email, password })
    };

    return fetch(REACT_APP_SERVER_API_URL + `/auth`+"?email="+email+"&password="+password, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function logout() {
    localStorage.removeItem('user');
}


function getAllInstitutions() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/institutions`, requestOptions)
        .then(handleResponse);
}

function insertOrUpdateInstitution(institution) {
    var obj = {"institution": institution};
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(obj)
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/institutions`, requestOptions)
        .then(handleResponse);
}

function deleteInstitution(IdInstitution) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(REACT_APP_SERVER_API_URL + "/private/institutions/" + IdInstitution, requestOptions)
        .then(handleResponse);
}

function importInstitution(file, IdInstitution) {
    const formData = new FormData();
    formData.append('file1', file);

    const requestOptions = {
        method: 'POST',
        headers: authHeaderFile(),
        body: formData,
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/institutions/` + IdInstitution, requestOptions)
        .then(handleResponse);
}

function sendMessageToUsers(IdInstitution) {
    const formData = new FormData();
    formData.append('IdInstitution', IdInstitution);

    const requestOptions = {
        method: 'POST',
        headers: authHeaderFile(),
        body: formData,
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/institutions_sendwelcomemessage`, requestOptions)
        .then(handleResponse);
}

function getAllTestsByIdTeacher(IdTeacher) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/tests` + "?IdTeacher=" + IdTeacher, requestOptions)
        .then(handleResponse);
}

function insertOrUpdateTest(test) {
    var obj = {"test": test};
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(obj)
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/tests`, requestOptions)
        .then(handleResponse);
}

function deleteTest(IdTest) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(REACT_APP_SERVER_API_URL + "/private/tests/" + IdTest, requestOptions)
        .then(handleResponse);
}

function getAllQuestionsByIdTest(IdTest) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/tests_questions` + "?IdTest=" + IdTest, requestOptions)
        .then(handleResponse);
}

function insertOrUpdateQuestion(question) {
    var obj = {"question": question};
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(obj)
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/tests_questions`, requestOptions)
        .then(handleResponse);
}

function deleteQuestion(IdQuestion) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(REACT_APP_SERVER_API_URL + "/private/tests_questions/" + IdQuestion, requestOptions)
        .then(handleResponse);
}

function importQuestions(file, IdTest) {
    const formData = new FormData();
    formData.append('file1', file);
    formData.append('IdTest', IdTest);


    const requestOptions = {
        method: 'POST',
        headers: authHeaderFile(),
        body: formData,
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/tests_questions/import`, requestOptions)
        .then(handleResponse);
}


function getAllClassesByIdTeacher(IdTeacher) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/classes` + "?IdTeacher=" + IdTeacher, requestOptions)
        .then(handleResponse);
}

function getAllClassesByIdInstitution(IdInstitution) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/classes` + "?IdInstitution=" + IdInstitution, requestOptions)
        .then(handleResponse);
}


function getAllReportsByIdClass(IdClass) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/reports` + "?IdClass=" + IdClass, requestOptions)
        .then(handleResponse);
}


function getAllStudentsByIdInstitution(IdInstitution) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/students_by_inst` + "?IdInstitution=" + IdInstitution, requestOptions)
        .then(handleResponse);
}

function insertOrUpdateStudent(user,student) {
    var obj = {"user": user,"student": student};
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(obj)
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/students`, requestOptions)
        .then(handleResponse);
}

function deleteStudent(IdUser) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(REACT_APP_SERVER_API_URL + "/private/students/" + IdUser, requestOptions)
        .then(handleResponse);
}


function getAllTeachersByIdInstitution(IdInstitution) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(REACT_APP_SERVER_API_URL + `/private/teachers_by_inst` + "?IdInstitution=" + IdInstitution, requestOptions)
        .then(handleResponse);
}

function insertOrUpdateTeacher(user,teacher) {
    var obj = {"user": user,"teacher": teacher};
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(obj)
    };
    return fetch(REACT_APP_SERVER_API_URL + `/private/teachers`, requestOptions)
        .then(handleResponse);
}

function deleteTeacher(IdUser) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(REACT_APP_SERVER_API_URL + "/private/teachers/" + IdUser, requestOptions)
        .then(handleResponse);
}


function updatePassword(IdInstitution, IdUser, password) {
    const formData = new FormData();
    formData.append('IdInstitution', IdInstitution);
    formData.append('IdUser', IdUser);
    formData.append('Password', password);

    const requestOptions = {
        method: 'POST',
        body: formData,
    };
    return fetch(REACT_APP_SERVER_API_URL + `/users/update_password`, requestOptions)
        .then(handleResponse);
}

function forgotPassword(Email) {
    const formData = new FormData();
    formData.append('Email', Email);

    const requestOptions = {
        method: 'POST',
        body: formData,
    };
    return fetch(REACT_APP_SERVER_API_URL + `/users/forgot_password`, requestOptions)
        .then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}