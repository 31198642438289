import React from 'react';
import { toast } from "react-toastify";

import ModalAddNewInstitution from './ModalAddNewInstitution';
import ModalImportInstitution from './ModalImportInstitution';
import ModalSendMessageToUsers from './ModalSendMessageToUsers';

import ModalConfirmAction from '../_other/ModalConfirmAction';


import {Button,ButtonToolbar} from 'react-bootstrap'

import { serverService } from '../../_services/server.service';

const $ = require('jquery');
$.DataTable = require('datatables.net');

require( 'datatables.net-bs4' );
require( 'datatables.net-select-bs4' );
require('../datatables.selected.css');

const columns = [
    {
        title: 'Denumire',
        width: 140,
        data: 'Name'
    },
    {
        title: 'Locatie',
        width: 100,
        data: 'LocationName'
    },
    {
        title: 'Status',
        width: 80,
        data: 'Status'
    },
    {
        title: 'Data trimitere raport',
        width: 100,
        data: 'DatetimeToSendReport'
    }
];

class InstitutionsTable extends React.Component {
    constructor(...args) {
        super(...args);

        this.state = {
            modalAddNewShow: false
            ,modalImportShow: false
            ,modalSendMessageShow: false
            ,modalConfirmDeleteShow:false
            ,selectedInstitution:{IdInstitution:0}
        };

        this.onInstitutionAdded = this.onInstitutionAdded.bind(this);
        this.onInstitutionImported = this.onInstitutionImported.bind(this);
        this.onMessageSent = this.onMessageSent.bind(this);
    }
    componentDidMount() {
        var table = $(this.refs.main).DataTable({
            //dom: '<"data-table-wrapper"<"toolbar"><"bottom"f>rtip>',
            dom: "<'data-table-wrapper'<'row'<'col-md-6 my-auto'<'toolbar'>l><'col-md-6'f>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>" +
                    ">",
            data: this.props.institutions,
            columns,
            ordering: true,
            paging:true,
            select: {
                style: 'single'
            },

            columnDefs: [
                {
                    "render": function ( data, type, row ) {
                        if(data === '0'){
                            return "Inactiva";
                        } else if(data === '1'){
                            return "Activa";
                        } else {
                            return data;
                        }
                    },
                    "targets": 2
                },
                {
                    "render": function ( data, type, row ) {
                        var date = new Date(data);

                        var MM = date.getMonth()+1;
                        var dd = date.getDate();
                        var hh = date.getHours();
                        var mm = date.getMinutes();

                        if(MM<10){
                            MM='0'+MM;
                        }
                        if(dd<10){
                            dd='0'+dd;
                        }
                        if(hh<10){
                            hh='0'+hh;
                        }
                        if(mm<10){
                            mm='0'+mm;
                        }

                        return  dd  + "." + MM + " ora " + hh;
                    },
                    "targets": 3
                }
            ],

            language: {
                "sProcessing":   "Procesează...",
                "sLengthMenu":   "Afișează _MENU_ înregistrări pe pagină",
                "sZeroRecords":  "Nu am găsit nimic - ne pare rău",
                "sInfo":         "Afișate de la _START_ la _END_ din _TOTAL_ înregistrări",
                "sInfoEmpty":    "Afișate de la 0 la 0 din 0 înregistrări",
                "sInfoFiltered": "(filtrate dintr-un total de _MAX_ înregistrări)",
                "sInfoPostFix":  "",
                "sSearch":       "Caută:",
                "sUrl":          "",
                "oPaginate": {
                    "sFirst":    "Prima",
                    "sPrevious": "Precedenta",
                    "sNext":     "Următoarea",
                    "sLast":     "Ultima"
                },
                select: {
                    rows: {
                        _: "",
                        0: "",
                        1: ""
                    }
                }
            }
        });

        $("#deleteBtn").hide();
        $("#editBtn").hide();
        $("#importBtn").hide();
        $("#sendMessageBtn").hide();

        table.on( 'select', function ( e, dt, type, indexes ) {
            //var rowData = table.rows( indexes ).data().toArray();

            $("#deleteBtn").show();
            $("#editBtn").show();
            $("#importBtn").show();
            $("#sendMessageBtn").show();
        });

        table.on( 'deselect', function ( e, dt, type, indexes ) {
            $("#deleteBtn").hide();
            $("#editBtn").hide();
            $("#importBtn").hide();
            $("#sendMessageBtn").hide();
        });

    }
    componentWillUnmount(){
        $('.data-table-wrapper')
            .find('table')
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(oldProps) {
        if(this.props.institutions !== oldProps.institutions){
            reloadTableData(this.props.institutions);
        }
    }

    render() {
        let modalAddNewOpen = (itemToEdit) => this.setState({ modalAddNewShow: true, selectedInstitution:itemToEdit });
        let closeAddNewModal = () => this.setState({ modalAddNewShow: false });

        let modalImportOpen = (itemToEdit) => this.setState({ modalImportShow: true, selectedInstitution:itemToEdit });
        let closeImportModal = () => this.setState({ modalImportShow: false });

        let modalSendMessageOpen = (itemToEdit) => this.setState({ modalSendMessageShow: true, selectedInstitution:itemToEdit });
        let closeSendMessageModal = () => this.setState({ modalSendMessageShow: false });

        let modalConfirmDeleteOpen = (itemToEdit) => this.setState({ modalConfirmDeleteShow: true });
        let modalConfirmDeleteClose = () => this.setState({ modalConfirmDeleteShow: false });

        let deleteSelectedItemWithConfirmation = () => {
            modalConfirmDeleteOpen();
        };

        let deleteSelectedItem = () => {

            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();

            var data = table.rows( { selected: true }).data();

            let IdInstitution = data[0].IdInstitution;

            serverService.deleteInstitution(IdInstitution)
                .then(result => {
                    if(result.status === 1){
                        toast.success("Institutie stearsa");
                        $("#deleteBtn").hide();
                        this.props.onInstitutionDeleted();
                    } else {
                        toast.error("Stergerea a esuat");
                    }

                    this.setState({ modalConfirmDeleteShow: false });
                });
        };

        let editSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();
            var data = table.rows( { selected: true }).data();
            modalAddNewOpen(data[0]);
        };

        let importSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();
            var data = table.rows( { selected: true }).data();
            modalImportOpen(data[0]);
        };

        let sendMessagetSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();
            var data = table.rows( { selected: true }).data();
            modalSendMessageOpen(data[0]);
        };

        return (
            <div>
                <ButtonToolbar>
                    <Button
                        variant="flat"
                        onClick={modalAddNewOpen}
                    >
                        Adauga
                    </Button>
                    &nbsp;
                    <Button
                        id="editBtn"
                        variant="flat"
                        onClick={editSelectedItem}
                    >
                        Editeaza
                    </Button>
                    &nbsp;
                    <Button
                        id="deleteBtn"
                        variant="flat"
                        onClick={deleteSelectedItemWithConfirmation}
                    >
                        Sterge
                    </Button>
                    &nbsp;
                    <Button
                        id="importBtn"
                        variant="flat"
                        onClick={importSelectedItem}
                    >
                        Importa
                    </Button>
                    &nbsp;
                    <Button
                        id="sendMessageBtn"
                        variant="flat"
                        onClick={sendMessagetSelectedItem}
                    >
                        Trimite mesaje
                    </Button>

                    <ModalAddNewInstitution
                        show={this.state.modalAddNewShow}
                        selectedInstitution={this.state.selectedInstitution}
                        onHide={closeAddNewModal}
                        onInstitutionAdded={this.onInstitutionAdded}
                    />

                    <ModalImportInstitution
                        show={this.state.modalImportShow}
                        selectedInstitution={this.state.selectedInstitution}
                        onHide={closeImportModal}
                        onInstitutionImported={this.onInstitutionImported}
                    />

                    <ModalSendMessageToUsers
                        show={this.state.modalSendMessageShow}
                        selectedInstitution={this.state.selectedInstitution}
                        onHide={closeSendMessageModal}
                        onMessageSent={this.onMessageSent}
                    />

                    <ModalConfirmAction
                        show={this.state.modalConfirmDeleteShow}
                        actionTitle="Confirmati stergerea"
                        actionBody="Sunteti sigur ca doriti stergerea institutiei?"
                        noBtnText="Nu, renunta"
                        yesBtnText="Da, confirm"
                        onConfirmNo={modalConfirmDeleteClose}
                        onConfirmYes={deleteSelectedItem}
                    />

                </ButtonToolbar>

                <table ref="main" className="table table-striped table-bordered" />
            </div>);
    }

    onInstitutionAdded(item){
        this.props.onInstitutionAdded(item);
        this.setState({modalAddNewShow: false});
    }

    onInstitutionImported(item){
        this.props.onInstitutionImported();
        //this.setState({modalImportShow: false});
    }

    onMessageSent(item){
        this.setState({modalSendMessageShow: false});
    }

}

function reloadTableData(institutions) {
    const table = $('.data-table-wrapper')
        .find('table')
        .DataTable();
    table.clear();
    table.rows.add(institutions);
    table.draw();

    $("#deleteBtn").hide();
    $("#editBtn").hide();
    $("#importBtn").hide();
    $("#sendMessageBtn").hide();
}


// InstitutionsTable.PropTypes = {
//     names: React.PropTypes.array
// };

export default InstitutionsTable;

