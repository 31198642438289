/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../_services/server.service';

class ModalAddNewTeacher extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',

            Name: "",
            Email: "",

            selectedUser: {},

        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});
        const {Name,Email} = this.state;

        // stop here if form is invalid
        if (!(Name)) {
            toast.error("Nu ati introdus toate datele obligatorii");
            return;
        }

        let user = {'UserType':2,'Name':Name,'Email': Email };
        let teacher = {'UserType':2,'IdInstitution': this.props.selectedInstitution.IdInstitution };

        if(this.state.selectedUser && typeof(this.state.selectedUser.IdUser) !== "undefined"){
            user.IdUser = this.state.selectedUser.IdUser;
            teacher.IdUser = this.state.selectedUser.IdUser;
        }

        serverService.insertOrUpdateTeacher(user,teacher)
            .then(result => {
                if(result.status === 1){
                    let messageStr = "Profesor adaugat";
                    if(this.state.selectedUser && typeof(this.state.selectedUser.IdUser) !== "undefined"){
                        messageStr = "Profesor adaugat";
                    }
                    toast.success(messageStr);
                    this.props.onUserAdded(result.extra);
                } else {
                    toast.error("Adaugare esuata");
                }
            });
    }

    componentDidUpdate(prevProps, prevState){

        if(this.props.selectedUser !== prevState.selectedUser){
            this.setState({selectedUser:this.props.selectedUser});

            if(this.state.selectedUser && typeof(this.state.selectedUser.IdUser) !== "undefined"){
                this.setState({
                    Name: this.props.selectedUser.Name,
                    Email:  this.props.selectedUser.Email,
                });
            } else {
                this.setState({
                    Name: "",
                    Email: "",
                });
            }
        }
    }


    render() {

        let titleStr = "Adaugare profesor";
        if(this.state.selectedUser && typeof(this.state.selectedUser.IdUser) !== "undefined"){
            titleStr = "Editare profesor";
        }

        return (
            <Modal
                {...this.props}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {titleStr}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit}>

                        <Form.Group controlId="formBasicName">
                            <Form.Label>*Nume profesor</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Introduceti nume"
                                          name="Name"
                                          value={this.state.Name}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicName">
                            <Form.Label>*Email profesor</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Introduceti emailul"
                                          name="Email"
                                          value={this.state.Email}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>


                        <br/><br/>

                        <Button variant="flat" type="submit">
                            {titleStr}
                        </Button>
                    </Form>
                </Modal.Body>
                <br/>
            </Modal>
        );
    }


}

export default ModalAddNewTeacher;