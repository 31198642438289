import React from 'react';
import {Form} from 'react-bootstrap'

class ClassesSelectUI extends React.Component {

    constructor() {
        super();

        this.state = {
            selectedValueId: '?',
        };
    }

    onClassSelected = (event) => {
        this.setState({ selectedValueId: event.target.value });
        this.props.onClassSelected(event.target.value);
    };

    render () {
        let classes = this.props.classes;

        let optionItems = "";
        if(classes && classes.length > 0){
             optionItems = classes.map((oneclass) => {
                 let selected = "";
                 if(oneclass.IdClass === this.props.selectedValueId){
                     selected = "selected";
                 }
                return <option key={oneclass.IdClass} value={oneclass.IdClass} selected={selected}>{oneclass.ClassNumber + oneclass.ClassLetter}</option>
             });
        }

        return (
            <Form.Group controlId="formBasicIdTest">
                <Form.Label>Selecteaza clasa</Form.Label>
                <Form.Control onChange={this.onClassSelected} size="sm" as="select">
                    <option key='0'>Select</option>
                    {optionItems}
                </Form.Control>
            </Form.Group>
        )

    }
}

export default ClassesSelectUI;
