/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

import { serverService } from '../../_services/server.service';

class ModalAddNewInstitution extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',

            Name: "",
            LocationName: "",
            Status: 0,
            DatetimeToSendReport: "",

            selectedInstitution: {}
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({submitted: true});
        const {Name,Status,LocationName, DatetimeToSendReport} = this.state;

        // stop here if form is invalid
        if (!(Name)) {
            toast.error("Nu ati introdus toate datele obligatorii");
            return;
        }


        let institution = {'Name':Name,'Status': Status ,'LocationName': LocationName,'DatetimeToSendReport': DatetimeToSendReport  };
        if(this.state.selectedInstitution && typeof(this.state.selectedInstitution.IdInstitution) !== "undefined"){
            institution.IdInstitution = this.state.selectedInstitution.IdInstitution;
        }

        serverService.insertOrUpdateInstitution(institution)
            .then(result => {
                if(result.status === 1){
                    let messageStr = "Institutie adaugata";
                    if(this.state.selectedInstitution && typeof(this.state.selectedInstitution.IdInstitution) !== "undefined"){
                        messageStr = "Institutie adaugata";
                    }
                    toast.success(messageStr);
                    this.props.onInstitutionAdded(result.extra);
                } else {
                    toast.error("Adaugare esuata");
                }
            });
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.selectedInstitution !== prevState.selectedInstitution){
            this.setState({selectedInstitution:this.props.selectedInstitution});

            if(this.state.selectedInstitution && typeof(this.state.selectedInstitution.IdInstitution) !== "undefined"){
                this.setState({
                    Name: this.props.selectedInstitution.Name,
                    Status:  this.props.selectedInstitution.Status,
                    LocationName: this.props.selectedInstitution.LocationName,
                    DatetimeToSendReport: this.props.selectedInstitution.DatetimeToSendReport,
                });
            } else {
                this.setState({
                    Name: "",
                    Status: 0,
                    LocationName:"",
                    DatetimeToSendReport:"",
                });
            }
        }
    }


    render() {

        let titleStr = "Adaugare institutie";
        if(this.state.selectedInstitution && typeof(this.state.selectedInstitution.IdInstitution) !== "undefined"){
            titleStr = "Editare institutie";
        }

        return (
            <Modal
                {...this.props}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {titleStr}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit}>

                        <Form.Group controlId="formBasicName">
                            <Form.Label>*Denumire institutie</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Introduceti denumire"
                                          name="Name"
                                          value={this.state.Name}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicName">
                            <Form.Label>*Localitate</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Introduceti localitate"
                                          name="LocationName"
                                          value={this.state.LocationName}
                                          onChange={this.handleChange}
                            />
                        </Form.Group>


                        <Form.Label>Status</Form.Label>
                        <Form.Control as="select" name="Status"
                                      value={this.state.Status}
                                      onChange={this.handleChange}>
                            <option value="0">Inactiva</option>
                            <option value="1">Activa</option>
                        </Form.Control>


                        <br/>
                        <Form.Group controlId="formBasicDate">
                            <Form.Label>Data trimitere raport</Form.Label>
                            <Form.Control size="sm" type="text" placeholder="Introduceti data (yyyy-mm-dd hh:mm:ss)"
                                          name="DatetimeToSendReport"
                                          value={this.state.DatetimeToSendReport}
                                          onChange={this.handleChange}
                            />
                            <Form.Text className="text-muted">
                                Format data: yyyy-mm-dd hh:mm:ss (anul nu se ia in considerare la trimiterea rapoartelor)
                            </Form.Text>
                        </Form.Group>

                        <br/>

                        <Button variant="flat" type="submit">
                            {titleStr}
                        </Button>
                    </Form>
                </Modal.Body>
                <br/>
            </Modal>
        );
    }

}

export default ModalAddNewInstitution;