import React from 'react';

import { serverService } from '../../_services/server.service';
import { toast } from "react-toastify";
import {Button,Modal,Form,Row,Col} from 'react-bootstrap';

import queryString from 'query-string';
import sha256 from 'sha256';
import sha1 from 'sha1';

class ResetPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            loading: false,
            error: '',
            Password1: '',
            Password2: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { Password1, Password2 } = this.state;

        if (!(Password1 && Password2)) {
            toast.warn("Nu ati completat toate campurile");
            return;
        } else if(Password1 !== Password2){
            toast.warn("Completati aceeasi parola in ambele campuri");
            return;
        }

        this.setState({ loading: true });

        let IdInstitution = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true }).IdInstitution;
        let IdUser = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true }).IdUser;

        let pass = sha1(Password1);

        serverService.updatePassword(IdInstitution, IdUser, pass)
            .then(
                response => {
                    this.setState({ loading: false });

                    if(response.status === 0){
                        toast.warn("Parola nu a fost actualizata");
                    } else {
                        toast.success("Parola actualizata cu succes");
                    }
                },
                error => {
                    toast.error("Server error");
                    this.setState({ error:error, loading: false })
                }
            );
    }

    render() {
        const { submitted, loading, error } = this.state;

        let h = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true }).h;
        let IdInstitution = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true }).IdInstitution;
        let IdUser = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true }).IdUser;

        let isValid = false;
        if(h && h.length > 0
            && IdInstitution && IdInstitution >= 0
            && IdUser && IdUser > 0){

            let salt = "785Tfr#463Btbt35^(08d3";
            let paramsStr = "action=view&IdInstitution=" + IdInstitution + "&IdUser=" + IdUser + "&";
            let generatedHash = sha256( salt + paramsStr);

            if(h === generatedHash){
                isValid = true;
            }
        }

        return (
            <>
            {isValid &&
                <Form onSubmit={this.handleSubmit}>
                    <br/>
                    <br/>
                    <Form.Group controlId="formBasicName">
                        <Form.Label>*Parola noua</Form.Label>
                        <Form.Control size="sm" type="password" placeholder="Introduceti parola"
                                      name="Password1"
                                      value={this.state.Password1}
                                      onChange={this.handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicName">
                        <Form.Label>*Parola noua</Form.Label>
                        <Form.Control size="sm" type="password" placeholder="Introduceti din nou parola"
                                      name="Password2"
                                      value={this.state.Password2}
                                      onChange={this.handleChange}
                        />
                    </Form.Group>

                    <Button variant="flat" type="submit">
                        Salveaza
                    </Button>
                </Form>
            }
            </>
        );
    }
}

export { ResetPasswordPage };