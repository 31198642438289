import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {

        var userStr = localStorage.getItem('user');

        if(userStr){
            var userObs = JSON.parse(userStr);
            if(userObs.UserType === "1" && props.location.pathname === "/"){ // admin
                return <Redirect to={{ pathname: '/institutii', state: { from: props.location } }} />;
            } else  if(userObs.UserType === "2" && props.location.pathname === "/"){ // prof
                return <Redirect to={{ pathname: '/teste', state: { from: props.location } }} />;
            } else {
                return <Component {...props} />
            }
        } else {
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }

    }
    } />
);