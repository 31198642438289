import React from 'react';
import { toast } from "react-toastify";
import {Row,Col} from 'react-bootstrap'

import { serverService } from '../../_services/server.service';

import InstitutionsSelectUI from '../_other/InstitutionsSelectUI';

import TeachersTable from './TeachersTable';

class TeachersPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isUsersTableVisible: false,
            selectedInstitutionIndex: -1,
            institutions: [],
            users: [],
        };

        this.onInstitutionSelected = this.onInstitutionSelected.bind(this);
        this.onUserAdded = this.onUserAdded.bind(this);
        this.onUserDeleted = this.onUserDeleted.bind(this);
    }


    componentDidMount() {
        this.setState({
            users: []
        });

        this.doFetchInstitutions();
    }


    render() {
        return (
            <div className="col-md-10 col-md-offset-2 mx-auto">

                <br/>

                <Row>
                    <Col sm={8}><InstitutionsSelectUI institutions={this.state.institutions} onInstitutionSelected ={this.onInstitutionSelected}/></Col>
                </Row>

                <br/>
                {this.state.isUsersTableVisible &&
                    <TeachersTable users={this.state.users}
                                    selectedInstitution={this.state.institutions[this.state.selectedInstitutionIndex]}
                                    onUserAdded={this.onUserAdded}
                                    onUserDeleted={this.onUserDeleted}

                    />
                }
            </div>
        );
    }


    doFetchInstitutions(){
        serverService.getAllInstitutions().then(institutionsRes => {
            this.setState({institutions: institutionsRes.data})
        });
    }

    doFetchUsers(IdInstitution){
        serverService.getAllTeachersByIdInstitution(IdInstitution).then(response => {
            this.setState({users: response.data, isUsersTableVisible: true})
        });
    }

    onInstitutionSelected(selectedInstitutionId){

        var institutions = this.state.institutions;
        var selectedInstitutionIndex = -1;
        for(var i=0;i<institutions.length;i++){
            if(institutions[i].IdInstitution === selectedInstitutionId){
                selectedInstitutionIndex = i;
                break;
            }
        }

        this.setState({selectedInstitutionIndex: selectedInstitutionIndex});

        if(selectedInstitutionIndex >= 0){
            this.doFetchUsers(this.state.institutions[selectedInstitutionIndex].IdInstitution);
        } else {
            this.setState({isUsersTableVisible: false})
        }
    }

    onUserAdded(User){
        this.doFetchUsers(this.state.institutions[this.state.selectedInstitutionIndex].IdInstitution);
    }

    onUserDeleted(){
        this.doFetchUsers(this.state.institutions[this.state.selectedInstitutionIndex].IdInstitution);
    }

}

export { TeachersPage };