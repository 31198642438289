/**
 * Created by Alex on 22-Mar-19.
 */
import React from 'react';
import {Button,Modal,Form,Row,Col} from 'react-bootstrap'
import { toast } from "react-toastify";

class ModalConfirmAction extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount(){
    }

    render() {
        return (
            <Modal
                {...this.props}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {this.props.actionTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.actionBody}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="flat" onClick={this.props.onConfirmNo}>
                        {this.props.noBtnText}
                    </Button>

                    <Button variant="flat" onClick={this.props.onConfirmYes}>
                        {this.props.yesBtnText}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default ModalConfirmAction;