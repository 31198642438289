import React from 'react';
import { toast } from "react-toastify";
import {Row,Col} from 'react-bootstrap'

import { serverService } from '../../_services/server.service';

import TestsSelectUI from '../_other/TestsSelectUI';

import QuestionsTable from './QuestionsTable';

class QuestionsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isQuestionsTableVisible: false,
            selectedTestIndex: {},
            tests: [],
            questions: [],
        };

        this.onTestSelected = this.onTestSelected.bind(this);
        this.onQuestionAdded = this.onQuestionAdded.bind(this);
        this.onQuestionDeleted = this.onQuestionDeleted.bind(this);
        this.onQuestionsImported = this.onQuestionsImported.bind(this);
    }


    componentDidMount() {
        this.setState({
            questions: []
        });

        this.doFetchTests();
    }


    render() {
        return (
            <div className="col-md-10 col-md-offset-2 mx-auto">

                <br/>

                <Row>
                    <Col sm={8}><TestsSelectUI tests={this.state.tests} onTestSelected ={this.onTestSelected}/></Col>
                </Row>

                <br/>
                {this.state.isQuestionsTableVisible &&
                    <QuestionsTable questions={this.state.questions}
                                    selectedTest={this.state.tests[this.state.selectedTestIndex]}
                                    onQuestionAdded={this.onQuestionAdded}
                                    onQuestionDeleted={this.onQuestionDeleted}
                                    onQuestionsImported = {this.onQuestionsImported}

                    />
                }
            </div>
        );
    }


    doFetchTests(){
        let user = JSON.parse(localStorage.getItem('user'));
        serverService.getAllTestsByIdTeacher(user.IdUser).then(testsRes => {
            this.setState({tests: testsRes.data})
        });
    }

    doFetchQuestions(IdTest){
        serverService.getAllQuestionsByIdTest(IdTest).then(questionsRes => {
            this.setState({questions: questionsRes.data, isQuestionsTableVisible: true})
        });
    }

    onTestSelected(selectedTestId){

        var tests = this.state.tests;
        var selectedTestIndex = -1;
        for(var i=0;i<tests.length;i++){
            if(tests[i].IdTest === selectedTestId){
                selectedTestIndex = i;
                break;
            }
        }

        this.setState({selectedTestIndex: selectedTestIndex});

        if(selectedTestIndex >= 0){
            this.doFetchQuestions(this.state.tests[selectedTestIndex].IdTest);
        } else {
            this.setState({isQuestionsTableVisible: false})
        }
    }

    onQuestionAdded(Question){
        this.doFetchQuestions(this.state.tests[this.state.selectedTestIndex].IdTest);
    }

    onQuestionDeleted(){
        this.doFetchQuestions(this.state.tests[this.state.selectedTestIndex].IdTest);
    }

    onQuestionsImported(){
        this.doFetchQuestions(this.state.tests[this.state.selectedTestIndex].IdTest);
    }
}

export { QuestionsPage };