import React from 'react';
import { toast } from "react-toastify";

import ModalAddNewQuestion from './ModalAddNewQuestion';
import ModalImportQuestions from './ModalImportQuestions';

import {Button,ButtonToolbar} from 'react-bootstrap'

import ModalConfirmAction from '../_other/ModalConfirmAction';

import { serverService } from '../../_services/server.service';

const $ = require('jquery');
$.DataTable = require('datatables.net');

require( 'datatables.net-bs4' );
require( 'datatables.net-select-bs4' );
require('../datatables.selected.css');

const columns = [
    {
        title: 'Intrebare',
        width: 150,
        data: 'Question'
    },
    {
        title: 'Raspuns1',
        width: 100,
        data: 'Answer1'
    },
    {
        title: 'Raspuns2',
        width: 100,
        data: 'Answer2'
    },
    {
        title: 'Raspuns3',
        width: 100,
        data: 'Answer3'
    },
    {
        title: 'Raspuns4',
        width: 100,
        data: 'Answer4'
    },
    {
        title: 'Rasp corect',
        width: 50,
        data: 'CorrectAnswer'
    },
    {
        title: 'Timp Rasp.',
        width: 50,
        data: 'AnswerTime'
    }
];

class QuestionsTable extends React.Component {
    constructor(...args) {
        super(...args);

        this.state = {
            modalAddNewShow: false
            ,modalConfirmDeleteShow:false
            ,selectedQuestion:{IdQuestion:0}
        };

        this.onQuestionAdded = this.onQuestionAdded.bind(this);
        this.onQuestionsImported = this.onQuestionsImported.bind(this);
    }
    componentDidMount() {
        var table = $(this.refs.main).DataTable({
            //dom: '<"data-table-wrapper"<"toolbar"><"bottom"f>rtip>',
            dom: "<'data-table-wrapper'<'row'<'col-md-6 my-auto'<'toolbar'>l><'col-md-6'f>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>" +
                    ">",
            data: this.props.questions,
            columns,
            ordering: true,
            paging:true,
            select: {
                style: 'single'
            },

            language: {
                "sProcessing":   "Procesează...",
                "sLengthMenu":   "Afișează _MENU_ înregistrări pe pagină",
                "sZeroRecords":  "Nu am găsit nimic - ne pare rău",
                "sInfo":         "Afișate de la _START_ la _END_ din _TOTAL_ înregistrări",
                "sInfoEmpty":    "Afișate de la 0 la 0 din 0 înregistrări",
                "sInfoFiltered": "(filtrate dintr-un total de _MAX_ înregistrări)",
                "sInfoPostFix":  "",
                "sSearch":       "Caută:",
                "sUrl":          "",
                "oPaginate": {
                    "sFirst":    "Prima",
                    "sPrevious": "Precedenta",
                    "sNext":     "Următoarea",
                    "sLast":     "Ultima"
                },
                select: {
                    rows: {
                        _: "",
                        0: "",
                        1: ""
                    }
                }
            }
        });


        $("#deleteBtn").hide();
        $("#editBtn").hide();

        table.on( 'select', function ( e, dt, type, indexes ) {
            //var rowData = table.rows( indexes ).data().toArray();

            $("#deleteBtn").show();
            $("#editBtn").show();
        });

        table.on( 'deselect', function ( e, dt, type, indexes ) {
            $("#deleteBtn").hide();
            $("#editBtn").hide();
        });

    }
    componentWillUnmount(){
        $('.data-table-wrapper')
            .find('table')
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(oldProps) {
        if(this.props.questions !== oldProps.questions){
            reloadTableData(this.props.questions);
        }
    }

    render() {
        let modalAddOpen = (itemToEdit) => this.setState({ modalAddNewShow: true, selectedQuestion:itemToEdit });
        let modalAddClose = () => this.setState({ modalAddNewShow: false });

        let modalImportOpen = (itemToEdit) => this.setState({ modalImportShow: true});
        let closeImportModal = () => this.setState({ modalImportShow: false });

        let modalConfirmDeleteOpen = (itemToEdit) => this.setState({ modalConfirmDeleteShow: true });
        let modalConfirmDeleteClose = () => this.setState({ modalConfirmDeleteShow: false });

        let deleteSelectedItemWithConfirmation = () => {
            modalConfirmDeleteOpen();
        };

        let deleteSelectedItem = () => {

            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();

            var data = table.rows( { selected: true }).data();

            let id = data[0].IdQuestion;

            serverService.deleteQuestion(id)
                .then(result => {
                    if(result.status === 1){
                        toast.success("Intrebare stearsa");
                        $("#deleteBtn").hide();
                        this.props.onQuestionDeleted();
                    } else {
                        toast.error("Stergerea a esuat");
                    }

                    this.setState({ modalConfirmDeleteShow: false });
                });
        };

        let editSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();
            var data = table.rows( { selected: true }).data();
            modalAddOpen(data[0]);
        };

        return (
            <div>
                <ButtonToolbar>
                    <Button
                        variant="flat"
                        onClick={modalAddOpen}
                    >
                        Adauga intrebare
                    </Button>

                    &nbsp;

                    <Button
                        variant="flat"
                        onClick={modalImportOpen}
                    >
                        Import intrebari
                    </Button>

                    &nbsp;

                    <Button
                        id="editBtn"
                        variant="flat"
                        onClick={editSelectedItem}
                    >
                        Editeaza intrebare
                    </Button>


                    &nbsp;
                    <Button
                        id="deleteBtn"
                        variant="flat"
                        onClick={deleteSelectedItemWithConfirmation}
                    >
                        Sterge
                    </Button>

                    <ModalAddNewQuestion
                        show={this.state.modalAddNewShow}
                        selectedQuestion={this.state.selectedQuestion}
                        selectedTest={this.props.selectedTest}
                        onHide={modalAddClose}
                        onQuestionAdded={this.onQuestionAdded}
                    />


                    <ModalImportQuestions
                        show={this.state.modalImportShow}
                        selectedTest={this.props.selectedTest}
                        onHide={closeImportModal}
                        onQuestionsImported={this.onQuestionsImported}
                    />

                    <ModalConfirmAction
                        show={this.state.modalConfirmDeleteShow}
                        actionTitle="Confirmati stergerea"
                        actionBody="Sunteti sigur ca doriti stergerea intrebarii?"
                        noBtnText="Nu, renunta"
                        yesBtnText="Da, confirm"
                        onConfirmNo={modalConfirmDeleteClose}
                        onConfirmYes={deleteSelectedItem}
                    />

                </ButtonToolbar>

                <table ref="main" className="table table-striped table-bordered" />
            </div>);
    }

    onQuestionAdded(item){
        this.props.onQuestionAdded(item);
        this.setState({modalAddNewShow: false});
    }

    onQuestionsImported(item){
        this.props.onQuestionsImported();
        this.setState({modalImportShow: false});
    }
}


function reloadTableData(values) {
    const table = $('.data-table-wrapper')
        .find('table')
        .DataTable();
    table.clear();
    table.rows.add(values);
    table.draw();

    $("#deleteBtn").hide();
    $("#editBtn").hide();
}

export default QuestionsTable;

