export function authHeader() {
    // return authorization header with basic auth credentials
    let user = JSON.parse(localStorage.getItem('user'));

    if (user['AuthToken'].length > 0) {
        return { 'tAuthorization': user['AuthToken'],
            'Content-Type':'application/json',
        };
    } else {
        return {};
    }
}


export function authHeaderFile() {
    // return authorization header with basic auth credentials
    let user = JSON.parse(localStorage.getItem('user'));

    if (user['AuthToken'].length > 0) {
        return { 'tAuthorization': user['AuthToken'],
            //'Content-Type':'multipart/form-data',
        };
    } else {
        return {};
    }
}