import React from 'react';
import { toast } from "react-toastify";

import ModalAddNewStudent from './ModalAddNewStudent';

import ModalConfirmAction from '../_other/ModalConfirmAction';

import {Button,ButtonToolbar} from 'react-bootstrap'

import { serverService } from '../../_services/server.service';

const $ = require('jquery');
$.DataTable = require('datatables.net');

require( 'datatables.net-bs4' );
require( 'datatables.net-select-bs4' );
require('../datatables.selected.css');

const columns = [
    {
        title: 'Id',
        width: 80,
        data: 'IdUser'
    },
    {
        title: 'Nume',
        width: 120,
        data: 'Name'
    },
    {
        title: 'Email',
        width: 120,
        data: 'Email'
    },
    {
        title: 'Clasa',
        width: 80,
        data: 'ClassName'
    }
];

class StudentsTable extends React.Component {
    constructor(...args) {
        super(...args);

        this.state = {
            modalAddNewShow: false
            ,modalConfirmDeleteShow:false
            ,selectedUser:{IdUser:0}
        };

        this.onUserAdded = this.onUserAdded.bind(this);
    }
    componentDidMount() {
        var table = $(this.refs.main).DataTable({
            //dom: '<"data-table-wrapper"<"toolbar"><"bottom"f>rtip>',
            dom: "<'data-table-wrapper'<'row'<'col-md-6 my-auto'<'toolbar'>l><'col-md-6'f>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>" +
                    ">",
            data: this.props.users,
            columns,
            ordering: true,
            paging:true,
            select: {
                style: 'single'
            },

            language: {
                "sProcessing":   "Procesează...",
                "sLengthMenu":   "Afișează _MENU_ înregistrări pe pagină",
                "sZeroRecords":  "Nu am găsit nimic - ne pare rău",
                "sInfo":         "Afișate de la _START_ la _END_ din _TOTAL_ înregistrări",
                "sInfoEmpty":    "Afișate de la 0 la 0 din 0 înregistrări",
                "sInfoFiltered": "(filtrate dintr-un total de _MAX_ înregistrări)",
                "sInfoPostFix":  "",
                "sSearch":       "Caută:",
                "sUrl":          "",
                "oPaginate": {
                    "sFirst":    "Prima",
                    "sPrevious": "Precedenta",
                    "sNext":     "Următoarea",
                    "sLast":     "Ultima"
                },
                select: {
                    rows: {
                        _: "",
                        0: "",
                        1: ""
                    }
                }
            }
        });

        $("#deleteBtn").hide();
        $("#editBtn").hide();

        table.on( 'select', function ( e, dt, type, indexes ) {
            //var rowData = table.rows( indexes ).data().toArray();

            $("#deleteBtn").show();
            $("#editBtn").show();
        });

        table.on( 'deselect', function ( e, dt, type, indexes ) {
            $("#deleteBtn").hide();
            $("#editBtn").hide();
        });

    }
    componentWillUnmount(){
        $('.data-table-wrapper')
            .find('table')
            .DataTable()
            .destroy(true);
    }

    componentDidUpdate(oldProps) {
        if(this.props.users !== oldProps.users){
            reloadTableData(this.props.users);
        }
    }

    render() {
        let modalAddNewOpen = (itemToEdit) => this.setState({ modalAddNewShow: true, selectedUser:itemToEdit });
        let closeAddNewModal = () => this.setState({ modalAddNewShow: false });

        let modalConfirmDeleteOpen = (itemToEdit) => this.setState({ modalConfirmDeleteShow: true });
        let modalConfirmDeleteClose = () => this.setState({ modalConfirmDeleteShow: false });

        let deleteSelectedItemWithConfirmation = () => {
            modalConfirmDeleteOpen();
        };

        let deleteSelectedItem = () => {

            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();

            var data = table.rows( { selected: true }).data();

            let IdUser = data[0].IdUser;

            serverService.deleteStudent(IdUser)
                .then(result => {
                    if(result.status === 1){
                        toast.success("Utilizator sters");
                        $("#deleteBtn").hide();
                        this.props.onUserDeleted();
                    } else {
                        toast.error("Stergerea a esuat");
                    }

                    this.setState({ modalConfirmDeleteShow: false });
                });
        };

        let editSelectedItem = () => {
            const table = $('.data-table-wrapper')
                .find('table')
                .DataTable();
            var data = table.rows( { selected: true }).data();
            modalAddNewOpen(data[0]);
        };

        return (
            <div>
                <ButtonToolbar>
                    <Button
                        variant="flat"
                        onClick={modalAddNewOpen}
                    >
                        Adauga
                    </Button>
                    &nbsp;
                    <Button
                        id="editBtn"
                        variant="flat"
                        onClick={editSelectedItem}
                    >
                        Editeaza
                    </Button>
                    &nbsp;
                    <Button
                        id="deleteBtn"
                        variant="flat"
                        onClick={deleteSelectedItemWithConfirmation}
                    >
                        Sterge
                    </Button>
                    &nbsp;

                    <ModalAddNewStudent
                        show={this.state.modalAddNewShow}
                        selectedInstitution={this.props.selectedInstitution}
                        selectedUser={this.state.selectedUser}
                        onHide={closeAddNewModal}
                        onUserAdded={this.onUserAdded}
                    />

                    <ModalConfirmAction
                        show={this.state.modalConfirmDeleteShow}
                        actionTitle="Confirmati stergerea"
                        actionBody="Sunteti sigur ca doriti stergerea utilizatorului?"
                        noBtnText="Nu, renunta"
                        yesBtnText="Da, confirm"
                        onConfirmNo={modalConfirmDeleteClose}
                        onConfirmYes={deleteSelectedItem}
                    />

                </ButtonToolbar>

                <table ref="main" className="table table-striped table-bordered" />
            </div>);
    }

    onUserAdded(item){
        this.props.onUserAdded(item);
        this.setState({modalAddNewShow: false});
    }

}

function reloadTableData(users) {
    const table = $('.data-table-wrapper')
        .find('table')
        .DataTable();
    table.clear();
    table.rows.add(users);
    table.draw();

    $("#deleteBtn").hide();
    $("#editBtn").hide();
}


export default StudentsTable;

