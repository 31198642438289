import React from 'react';
import { toast } from "react-toastify";
import {Row,Col} from 'react-bootstrap'

import { serverService } from '../../_services/server.service';

import ClassesSelectUI from '../_other/ClassesSelectUI';

import ReportsTable from './ReportsTable';

class ReportsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isReportsTableVisible: false,
            selectedClassIndex: {},
            classes: [],
            reports: [],
        };

        this.onClassSelected = this.onClassSelected.bind(this);
    }


    componentDidMount() {
        this.setState({
            reports: []
        });

        this.doFetchClasses();
    }


    render() {
        return (
            <div className="col-md-10 col-md-offset-2 mx-auto">

                <br/>

                <Row>
                    <Col sm={8}><ClassesSelectUI classes={this.state.classes} onClassSelected ={this.onClassSelected}/></Col>
                </Row>

                <br/>
                {this.state.isReportsTableVisible &&
                    <ReportsTable reports={this.state.reports}
                                    selectedClass={this.state.classes[this.state.selectedClassIndex]}
                    />
                }
            </div>
        );
    }


    doFetchClasses(){
        let user = JSON.parse(localStorage.getItem('user'));
        serverService.getAllClassesByIdTeacher(user.IdUser).then(classesRes => {
            this.setState({classes: classesRes.data})
        });
    }

    doFetchReports(IdClasses){
        serverService.getAllReportsByIdClass(IdClasses).then(reportsRes => {
            this.setState({reports: reportsRes.data, isReportsTableVisible: true})
        });
    }

    onClassSelected(selectedClassId){
        var classes = this.state.classes;
        var selectedClassIndex = -1;
        for(var i=0;i<classes.length;i++){
            if(classes[i].IdClass === selectedClassId){
                selectedClassIndex = i;
                break;
            }
        }

        this.setState({selectedClassIndex: selectedClassIndex});

        if(selectedClassIndex >= 0){
            this.doFetchReports(this.state.classes[selectedClassIndex].IdClass);
        } else {
            this.setState({isReportsTableVisible: false})
        }
    }

}

export { ReportsPage };